
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1600px,
    xxl: 2500px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 800px,
    xl: 960px,
    xxl: 1440px,
);

html{
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#root {
    font-family: "Roboto", sans-serif;
}

.MuiDataGrid-columnSeparator{
    display: none !important;
}

a{
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }

}

body::-webkit-scrollbar {
    display: none;
  }
  
 